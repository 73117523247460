import * as React from "react";
import { Link } from "gatsby";
import Layout from "src/components/Layout";
import Heading from "src/components/Heading";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <div className="min-h-screen bg-white px-4 py-14 sm:px-6 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-2xl">
          <main className="sm:flex">
            <p className="text-red text-4xl font-extrabold sm:text-5xl">404</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <Heading size="h2" className="text-blue">
                  Not found
                </Heading>
                <span className="text-blue mt-1 block text-base">
                  Sorry, we couldn't find what you're looking for. If you still
                  can't find the content you need either get in touch with us
                  below.
                </span>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Link
                  to="/get-in-touch/"
                  prefetch="true"
                  className="line-crop font-display bg-red hover:bg-darkred inline-flex cursor-pointer items-center justify-center px-[30px] py-[14px] text-[1.3rem] uppercase tracking-normal text-white transition duration-300"
                >
                  Get in touch
                  <i className="fas fa-chevron-right ml-3 inline cursor-pointer text-xs"></i>
                </Link>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
